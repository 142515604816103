import { ILexingError, IRecognitionException } from "chevrotain";

export interface Range {
  start: number;
  end: number;
}

export abstract class CalculatorError extends Error {
  constructor(message: string, public range?: Range) {
    super(message);
  }

  static fromLexingError(error: ILexingError, expression: string): CalculatorError {
    let e;
    if (expression[error.offset] === "x") {
      e = new XError({ start: error.offset, end: error.offset + error.length });
    } else {
      e = new InvalidInputError({ start: error.offset, end: error.offset + error.length });
    }
    e.cause = error;
    return e;
  }

  static fromParsingError(error: IRecognitionException, expression: string): CalculatorError {
    let range: Range;
    if (Number.isNaN(error.token.startOffset)) {
      range = {
        start: expression.length,
        end: expression.length,
      };
    } else {
      range = {
        start: error.token.startOffset,
        end: error.token.endOffset ?? error.token.startOffset,
      };
    }
    const e = new InvalidInputError(range);
    e.cause = error;
    return e;
  }

  equals(other: CalculatorError): boolean {
    return (
      this.message === other.message && this.range?.start === other.range?.start && this.range?.end === other.range?.end
    );
  }
}

export class InvalidInputError extends CalculatorError {
  constructor(range?: Range) {
    super("Invalid input", range);
  }
}

export class DivisionByZeroError extends CalculatorError {
  constructor(range?: Range) {
    super("Division by zero", range);
  }
}

export class NegativeBaseError extends CalculatorError {
  constructor(range?: Range) {
    super("Negative base with non-integer exponent", range);
  }
}

export class InfinityError extends CalculatorError {
  constructor(range?: Range) {
    super("Result too large", range);
  }
}

export class NaNError extends CalculatorError {
  constructor(range?: Range) {
    super("Calculation error", range);
  }
}

export class XError extends CalculatorError {
  constructor(range?: Range) {
    super('Unexpected "x". Did you mean to type "*"?', range);
  }
}

export class ParenthesisError extends CalculatorError {
  constructor(range?: Range) {
    super("Can't insert parenthesis here", range);
  }
}
