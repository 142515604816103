import React from "react";

export default class Dialog extends React.Component<{
  dialogRef: React.RefObject<HTMLDialogElement>;
  children: React.ReactNode;
  [key: string]: any;
}> {
  get dialog() {
    if (this.props.dialogRef.current === null) {
      throw new Error("dialogRef is null");
    }
    return this.props.dialogRef.current;
  }

  onClick = (event: MouseEvent) => {
    // If click is on the backdrop, close the dialog.
    if (event.target === this.dialog) {
      this.dialog.close();
    }
  };

  componentDidMount() {
    this.dialog.addEventListener("click", this.onClick);
  }

  componentWillUnmount() {
    this.dialog.removeEventListener("click", this.onClick);
  }

  render() {
    let { dialogRef, ...props } = this.props;
    return (
      <>
        <dialog ref={dialogRef} className="p-0 rounded-[10px]" {...props}>
          {/* Create a new block formatting context using `display: flow-root`, so that
          only clicks on the backdrop have event.target === this.dialog, even if inner
          divs have margin. */}
          <div className="flow-root w-full h-full">
            <CloseButton onClick={() => this.dialog.close()} />
            <div className="h-full dialog-content">{this.props.children}</div>
          </div>
        </dialog>
      </>
    );
  }
}

function CloseButton(props: { onClick: () => void }) {
  return (
    <a
      href="#"
      onClick={(event) => {
        props.onClick();
        event.preventDefault();
      }}
      className="absolute m-[-1.5rem] right-[21px] top-[12px]
        p-[1.5rem] fill-white [&:hover]:fill-[rgba(255,255,255,0.8)] [&:active]:fill-[rgba(255,255,255,0.6)]"
      aria-label="close"
      role="button"
    >
      <svg className="inline-block" aria-hidden="true" width="17" height="17" viewBox="0 0 14 14">
        <path d="M13.3002 0.709971C12.9102 0.319971 12.2802 0.319971 11.8902 0.709971L7.00022 5.58997L2.11022 0.699971C1.72022 0.309971 1.09021 0.309971 0.700215 0.699971C0.310215 1.08997 0.310215 1.71997 0.700215 2.10997L5.59022 6.99997L0.700215 11.89C0.310215 12.28 0.310215 12.91 0.700215 13.3C1.09021 13.69 1.72022 13.69 2.11022 13.3L7.00022 8.40997L11.8902 13.3C12.2802 13.69 12.9102 13.69 13.3002 13.3C13.6902 12.91 13.6902 12.28 13.3002 11.89L8.41021 6.99997L13.3002 2.10997C13.6802 1.72997 13.6802 1.08997 13.3002 0.709971Z" />
      </svg>
    </a>
  );
}
