import React from "react";
import {
  observable,
  action,
  makeObservable,
  computed,
  when,
  IReactionDisposer,
  runInAction,
  reaction,
  toJS,
} from "mobx";
import { disposeOnUnmount, observer } from "mobx-react";
import { Result, Ok, Err } from "ts-results-es";
import { pushState, replaceState } from "history-throttled";

import * as parser from "./parser";
import { evaluate, parse, lex, fixupTrailingOperators, fixupTrailingParentheses, Calculation } from "./parser";
import { CalculatorError, InvalidInputError, ParenthesisError } from "./errors";
import {
  ACButton,
  OperatorButton,
  NumberButton,
  EqualsButton,
  BackspaceButton,
  ToolButton,
} from "./calculator-buttons";
import { Decimal, renderDecimal } from "./decimal";
import { CstNode } from "chevrotain";
import { ErrorLoggerContext } from "./error-logger";
import HelpDialogContent from "@/components/help";
import Dialog from "@/components/dialog";
import Icon from "@mdi/react";
import { mdiAlertCircleOutline, mdiHelpCircleOutline } from "@mdi/js";

@observer
export class Calculator extends React.Component {
  static RESULT_MAX_CHARS = 24;
  static RESULT_DECIMAL_PLACES = 7;
  static JIT_SCALE = 2;

  @observable expression = "";
  @observable error: CalculatorError | null = null;
  @observable previousCalculation: Calculation | null = null;

  // State for pushState history.
  getState = () => {
    // We could consider storing this.error in the state as well, but then we'd
    // have to handle toast and regular errors differently. It's easier to just
    // resetError() on state change.
    //
    // We need to use toJS so we don't get MobX's proxy objects. (They can't be
    // used in pushState/replaceState state.)
    let prev = toJS(this.previousCalculation);
    let prevCopy = null;
    if (prev !== null) {
      prevCopy = {
        cst: prev.cst,
        rendered: prev.rendered,
        decimal: prev.decimal.toString(),
      };
    }
    return {
      expression: toJS(this.expression),
      previousCalculation: prevCopy,
    };
  };
  setState = (state: ReturnType<typeof Calculator.prototype.getState>) => {
    this.setExpression(state.expression, false);
    let prev = state.previousCalculation;
    if (prev !== null) {
      this.previousCalculation = {
        cst: prev.cst,
        rendered: prev.rendered,
        decimal: new Decimal(prev.decimal),
      };
    } else {
      this.previousCalculation = null;
    }
    this.resetError();
  };

  @observable lastSeenError: CalculatorError | null = null;
  @disposeOnUnmount _lastSeenErrorUpdater: IReactionDisposer | null = null;

  inputFieldRef = React.createRef<HTMLInputElement>();
  get inputField() {
    if (this.inputFieldRef.current === null) {
      throw new Error("Calculator: inputFieldRef is null");
    }
    return this.inputFieldRef.current;
  }
  jitDisplayRef = React.createRef<JitDisplay>();
  get jitDisplay() {
    if (this.jitDisplayRef.current === null) {
      throw new Error("Calculator: jitDisplayRef is null");
    }
    return this.jitDisplayRef.current;
  }
  errorContainerRef = React.createRef<HTMLDivElement>();
  get errorContainer() {
    if (this.errorContainerRef.current === null) {
      throw new Error("Calculator: errorElementRef is null");
    }
    return this.errorContainerRef.current;
  }
  helpDialogRef = React.createRef<HTMLDialogElement>();
  get helpDialog() {
    if (this.helpDialogRef.current === null) {
      throw new Error("Calculator: helpDialogRef is null");
    }
    return this.helpDialogRef.current;
  }
  screenReaderToastContainerRef = React.createRef<HTMLDivElement>();
  get screenReaderToastContainer() {
    if (this.screenReaderToastContainerRef.current === null) {
      throw new Error("Calculator: screenReaderToastContainerRef is null");
    }
    return this.screenReaderToastContainerRef.current;
  }

  fadeDuration = 100;
  slideDuration = 117;
  // Testing aids
  renderDoesThrow = false;
  static renderAlwaysThrows = false;

  static contextType = ErrorLoggerContext;
  declare context: React.ContextType<typeof ErrorLoggerContext>;
  get errorLogger(): React.ContextType<typeof ErrorLoggerContext> {
    return this.context;
  }

  constructor(props: Record<string, unknown>) {
    super(props);
    makeObservable(this);

    // We use reaction instead of autorun, as autorun doesn't play nice with
    // runInAction.
    this._lastSeenErrorUpdater = reaction(
      () => this.error,
      (error) => {
        runInAction(() => {
          if (error !== null) {
            this.lastSeenError = error;
          }
        });
      }
    );
  }

  componentDidMount = () => {
    window.addEventListener("keypress", this.handleKeyPress);
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("popstate", this.handlePopState);

    this.updateExpressionFromHash();

    action(() => {
      this.expression = this.inputField.value; // catch up after hydration
    })();
    this.inputField.focus();

    // Used in TESTING.md
    (window as any).calculator = this;
    (window as any).Calculator = this.constructor;
  };

  componentWillUnmount = () => {
    window.removeEventListener("keypress", this.handleKeyPress);
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("popstate", this.handlePopState);
    this.resetError();
  };

  @computed
  get result(): Result<Calculation, CalculatorError> {
    try {
      return this.computeResult();
    } catch (e) {
      if (e instanceof CalculatorError) {
        return Err(e);
      } else {
        this.errorLogger.log(e, "Calculator.result");
        return Err(new InvalidInputError());
      }
    }
  }

  computeResult(): Result<Calculation, CalculatorError> {
    // Note that this can signal errors both as Err(e) and using throw
    if (this.expression === "") {
      return Ok({ rendered: "", decimal: new Decimal(0), cst: null });
    }
    if (this.repeatedResult !== null) {
      return this.repeatedResult;
    }
    let tokens = lex(this.expression);
    tokens = fixupTrailingParentheses(tokens);
    let cst = parse(this.expression, tokens);
    return this.getCalculation(cst);
  }

  // Repeatedly pressing enter repeats the previous operation; e.g. `10+2==`
  // yields `14`. Returns null if pressing enter wouldn't trigger a repeated
  // operation.
  get repeatedResult(): Result<Calculation, CalculatorError> | null {
    if (this.previousCalculation === null || this.previousCalculation.cst === null) {
      return null;
    }
    if (this.expression.trim() !== this.previousCalculation.rendered) {
      return null;
    }
    let cstFragment = parser.topLevelBinaryOperation(this.previousCalculation?.cst);
    if (cstFragment === null) {
      return null;
    }
    // Deep clone to avoid mutating the previous calculation
    cstFragment = JSON.parse(JSON.stringify(cstFragment)) as CstNode;
    // Given a cst for 2+3+4+5, turn it into <result>+5 to repeat the last operation.
    cstFragment.children.operator = [cstFragment.children.operator[cstFragment.children.operator.length - 1]];
    cstFragment.children.rhs = [cstFragment.children.rhs[cstFragment.children.rhs.length - 1]];
    cstFragment.children.lhs = [parse(this.previousCalculation.decimal.toString())];
    // cstFragment isn't a top-level expression node, but all our CST traversals
    // are forgiving enough to use it in place of one.
    return this.getCalculation(cstFragment);
  }

  getCalculation = (cst: CstNode): Result<Calculation, CalculatorError> => {
    try {
      let decimal = evaluate(cst, this.previousCalculation);
      let rendered = renderDecimal(decimal, {
        maxChars: Calculator.RESULT_MAX_CHARS,
        decimalPlaces: Calculator.RESULT_DECIMAL_PLACES,
        ellipsis: "…",
      });
      return Ok({ decimal, rendered, cst });
    } catch (e) {
      if (e instanceof CalculatorError) {
        return Err(e);
      } else {
        throw e;
      }
    }
  };

  @computed
  get justInTimeResult(): string | null {
    try {
      return (this.constructor as any).computeJustInTimeResult(this.expression, this.previousCalculation);
    } catch (e) {
      if (e instanceof CalculatorError) {
        return null;
      } else {
        this.errorLogger.log(e, "Calculator.justInTimeResult");
        return null;
      }
    }
  }

  static computeJustInTimeResult = (expression: string, previousCalculation: Calculation | null): string | null => {
    let tokens = lex(expression); // might throw CalculatorError
    tokens = fixupTrailingOperators(tokens);
    tokens = fixupTrailingParentheses(tokens);

    // Don't show just-in-time result if there's only a number
    let tokenNames = tokens.map((token) => token.tokenType.name).join(",");
    if (["NumberLiteral", "Plus,NumberLiteral", "Minus,NumberLiteral"].includes(tokenNames)) {
      return null;
    }

    let result = evaluate(parse(expression, tokens), previousCalculation); // might throw CalculatorError
    return renderDecimal(result, {
      maxChars: Calculator.RESULT_MAX_CHARS,
      decimalPlaces: Calculator.RESULT_DECIMAL_PLACES,
      ellipsis: "…",
    });
  };

  pressEnter() {
    if (this.result.ok) {
      let calculation = this.result.val;
      let jitScale = this.jitDisplay.scale;
      let animationType =
        calculation.rendered === this.justInTimeResult &&
        jitScale >= 1 && // if jitScale < 1, we are overflowing the input field
        !window.matchMedia("(prefers-reduced-motion: reduce)").matches
          ? "slide"
          : "fade";
      let needsReplace = this.repeatedResult !== null;
      this.previousCalculation = calculation;
      this.resetError();
      this.setExpression(calculation.rendered, false);
      // On screen readers, inform the user of the updated input field. We can't
      // just set aria-live on the input field to accomplish this, as this
      // doesn't work on VoiceOver.
      this.addScreenReaderToast(calculation.rendered);
      this.pushStateWithoutURLChange();
      if (needsReplace) {
        // When we do repeated operations, update the URL. Ideally we'd put the
        // synthesized repeated expression in the URL, but Jo didn't know an
        // easy way to reconstruct it from a synthetic CST node
        // (https://stackoverflow.com/questions/75548500/chevrotain-cst-to-string),
        // so we just update it with the rendered result.
        this.replaceState();
      }
      if (this.inputField.scrollTo !== undefined) {
        this.inputField.scrollTo(this.inputField.scrollWidth, 0); // scroll to the right
      }
      if (animationType === "slide") {
        // prettier-ignore
        this.inputField.animate([
          {
            transform: `translateY(var(--jit-offset)) scale(${jitScale})`,
            fontWeight: "var(--jit-font-weight)",
            opacity: "var(--jit-opacity)",
          }, {
            transform: "translateY(0px)",
            fontWeight: "var(--input-font-weight)",
            opacity: "1",
          }
        ], {
          duration: this.slideDuration,
          easing: "ease-in-out",
        });
        // prettier-ignore
        this.inputField.animate([
          { caretColor: "transparent", },
          { caretColor: "inherit", }
        ], {
          duration: this.slideDuration,
          easing: "steps(1,jump-end)",
        });
      } else {
        // prettier-ignore
        this.inputField.animate([
          { opacity: 0, },
          { opacity: 1, }
        ], {
          duration: this.fadeDuration,
          easing: "ease-in-out",
        });
      }
    } else {
      this.setError(this.result.val);
    }
  }

  ac() {
    if (this.expression !== "") {
      // When iOS VoiceOver is enabled, we get a flood of "Escape" events, one
      // for every ancestor of the <input>. Therefore we only send the
      // screen-reader toast on the first AC, when expression !== "".
      this.addScreenReaderToast("cleared");
    }
    this.setExpression("");
    this.resetError();
    this.previousCalculation = null;
  }

  insertSmartParenthesis() {
    this.mutateInputField((start, end) => {
      let textBeforeCursor = this.inputField.value.slice(0, start);
      let paren = smartParenthesis(textBeforeCursor);
      if (paren !== null) {
        this.inputField.setRangeText(paren, start, end, "end");
      } else {
        this.setErrorToast(new ParenthesisError());
      }
    });
  }

  mutateInputField(fn: (start: number, end: number) => void, updateHash = true) {
    if (updateHash) {
      this.pushInitialState();
    }
    this.focusInputField();
    try {
      fn(
        this.inputField.selectionStart ?? this.inputField.value.length,
        this.inputField.selectionEnd ?? this.inputField.value.length
      );
    } finally {
      this.expression = this.inputField.value;
      if (updateHash) {
        this.replaceState();
      }
    }
  }

  focusInputField = () => {
    if (this.inputField !== document.activeElement) {
      this.inputField.focus();
      this.inputField.selectionStart = this.inputField.selectionEnd = this.inputField.value.length;
    }
  };

  insert = (text: string) => {
    this.mutateInputField((start, end) => {
      this.inputField.setRangeText(text, start, end, "end");
    });
  };

  setExpression = (expression: string, updateHash = true) => {
    this.mutateInputField(() => {
      this.inputField.value = expression;
      this.inputField.selectionStart = this.inputField.selectionEnd = expression.length;
    }, updateHash);
  };

  disposeErrorUpdater: IReactionDisposer | null = null;
  errorTimeout: number | null = null;
  @action
  resetError = () => {
    if (this.disposeErrorUpdater !== null) {
      this.disposeErrorUpdater();
      this.disposeErrorUpdater = null;
    }
    if (this.errorTimeout !== null) {
      clearTimeout(this.errorTimeout);
      this.errorTimeout = null;
    }
    this.error = null;
  };

  _setError = (error: CalculatorError) => {
    this.resetError();
    this.error = error;
    gtag("event", "calculator_error", {
      error_name: error.constructor.name,
    });

    // prettier-ignore
    this.errorContainer.animate([
      { opacity: 0, },
      { opacity: 1, }
    ], {
      duration: this.fadeDuration,
      easing: "ease-out",
    });
  };

  setError = (error: CalculatorError) => {
    this._setError(error);
    this.disposeErrorUpdater = when(() => this.result.ok || !this.result.val.equals(this.error!), this.resetError);
  };

  toastTimeout = 4000;
  setErrorToast = (error: CalculatorError) => {
    this._setError(error);
    let currentExpression = this.expression;
    this.disposeErrorUpdater = when(() => this.expression !== currentExpression, this.resetError);
    this.errorTimeout = window.setTimeout(this.resetError, this.toastTimeout);
  };

  addScreenReaderToast(message: string) {
    let toast = document.createElement("div");
    toast.ariaAtomic = "true";
    toast.textContent = message;
    this.screenReaderToastContainer.appendChild(toast);
    setTimeout(() => {
      toast.remove();
    }, 100);
  }

  @action
  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      this.ac();
    } else if (event.key === "Backspace") {
      this.focusInputField();
    }
  };

  @action
  handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter" || event.key === "=") {
      if (!event.repeat) {
        this.pressEnter();
      }
      event.preventDefault();
    } else if (event.key === ",") {
      this.insert(".");
      event.preventDefault();
    } else {
      this.focusInputField();
    }
  };

  initialState = true;
  pushInitialState = () => {
    if (this.initialState) {
      this.initialState = false;
      this.pushStateWithoutURLChange();
    }
  };

  updateExpressionFromHash = () => {
    const hash = decodeURI(new URL(document.URL).hash.slice(1));
    // Guard against overwriting user input during hydration. TODO test me
    if (hash !== this.expression) {
      this.setExpression(hash, false);
    }
  };

  @action
  handlePopState = (event?: PopStateEvent) => {
    let calculatorState = event?.state?.calculatorState;
    if (calculatorState !== null && calculatorState !== undefined) {
      this.setState(calculatorState);
    } else {
      this.updateExpressionFromHash();
    }
  };

  encodeHash = (expr: string): string => {
    return expr.length ? `#${encodeURI(expr.replace("…", ""))}` : " ";
  };

  replaceState = () => {
    // We are wrapping the state in { calculatorState: ... } to tell our states
    // apart from states created by the Next.js development server.
    replaceState({ calculatorState: this.getState() }, "", this.encodeHash(this.expression));
  };

  pushStateWithoutURLChange = () => {
    pushState({ calculatorState: this.getState() }, "", document.URL);
  };

  @action
  clickButton = (button: string) => {
    const buttonToUnicode: Record<string, string> = {
      "-": "−",
      "*": "×",
      "/": "÷",
    };

    switch (button) {
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
      case ".":
      case "+":
      case "-":
      case "*":
      case "/":
      case "^":
      case "%":
      case "(": // used by test suite
      case ")": // used by test suite
        this.insert(buttonToUnicode[button] ?? button);
        break;
      case "()":
        this.insertSmartParenthesis();
        break;
      case "=":
        this.pressEnter();
        break;
      case "Backspace":
        this.mutateInputField((start, end) => {
          if (document.execCommand !== undefined) {
            document.execCommand("delete");
          } else {
            // Fallback for jsdom
            if (start === end) {
              if (start > 0) {
                this.inputField.setRangeText("", start - 1, end, "end");
              }
            } else {
              this.inputField.setRangeText("", start, end, "end");
            }
          }
        });
        break;
      case "AC":
        this.ac();
        break;
      default:
        throw new Error(`Unknown button: ${button}`);
    }
  };

  // Called when the user changes the input field. For synthetic changes (such
  // as in response to button clicks), see mutateInputField.
  @action
  inputDidChange = () => {
    this.pushInitialState();
    this.expression = this.inputField.value;
    this.replaceState();
  };

  @action
  handleStrayClick = (event: React.MouseEvent) => {
    // Stop the mouseup at the end of a text selection from registering
    // as a click. https://stackoverflow.com/a/31982533
    if (window.getSelection()?.type === "Range") {
      return;
    }
    // Ignore clicks on the JIT display. Note that we can't set onClick
    // on the textElement itself (and call stopPropagation), because
    // this breaks aria-live support on Windows Narrator.
    if (event.target === this.jitDisplay.textElement) {
      return;
    }
    this.inputField.focus();
  };

  render() {
    if (this.renderDoesThrow || Calculator.renderAlwaysThrows) {
      throw new Error("Simulated error");
    }
    let iconSize = "w-[24px] h-[24px] tablet:w-[30px] tablet:h-[30px]";
    return (
      <>
        <div className="calculator" onClick={this.handleStrayClick}>
          <div className="header-row flex items-start justify-center">
            {/* Help icon */}
            <button
              className="help-icon shrink-0 p-[5px] m-[-5px] select-none"
              onClick={() => this.helpDialog.showModal()}
              aria-label="Help"
            >
              <Icon
                className={`${iconSize}`}
                path={mdiHelpCircleOutline}
                // aria-hidden is needed so iPad VoiceOver says "button" rather than "image" when touching icon.
                aria-hidden="true"
                color="inherit"
              />
            </button>

            {/* Error message container. We set a fixed height and let it overflow to accomodate
            no error message, one line or two lines without layout shifts. */}
            <div
              className="shrink-1 pt-[3px] px-[5px] mx-auto text-[12px] tablet:text-[14px] h-[30px]"
              aria-live="assertive"
              aria-atomic="true"
              role="alert"
              aria-hidden={this.error === null}
            >
              <div
                className="alert flex items-center justify-center rounded-[15px] transition-opacity duration-75"
                style={{
                  opacity: this.error !== null ? 1 : 0,
                }}
                ref={this.errorContainerRef}
              >
                <Icon path={mdiAlertCircleOutline} size="16px" title="Error" aria-hidden="true" className="m-[5px]" />
                {/* Use lastSeenError so the text doesn't disappear during fadeout */}
                <p className="mr-[10px]">{this.lastSeenError?.message}</p>
              </div>
            </div>

            <ScreenReaderOnly>
              <div ref={this.screenReaderToastContainerRef} role="log" aria-live="polite" />
            </ScreenReaderOnly>

            {/* Space for settings icon. Must be same width as help icon so that error messages are centered. */}
            <div className={`${iconSize} shrink-0`}></div>
          </div>

          <div className="display-spacer"></div>

          {/* Display */}
          <div className="display px-4">
            <div className="relative h-full">
              <div className="absolute top-0 w-full">
                <input
                  autoFocus={true}
                  ref={this.inputFieldRef}
                  // Don't use `value` without testing carefully if
                  // backspace or other input causes inputField.value and
                  // this.expression to get out of sync.
                  defaultValue={this.expression}
                  // Don't use onChange here. React's implementation of it
                  // (which is very similar to vanilla onInput) doesn't play
                  // nice with programmatic modification using setRangeText.
                  onInput={this.inputDidChange}
                  className="calc-input absolute top-0 right-0 text-end w-full origin-right outline-none"
                  // Suppress virtual keyboard on mobile.
                  inputMode="none"
                  aria-label="Calculate"
                  aria-live="off"
                  aria-atomic="true"
                  // Don't set `role="math"`, as it causes Windows Narrator on
                  // Edge not to focus the input field on page load.
                />
              </div>

              <div className="absolute w-full select-text" style={{ top: "var(--jit-offset)" }}>
                <JitDisplay calculator={this} ref={this.jitDisplayRef} />
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="button-grid select-none" onClick={(e) => e.stopPropagation()}>
            <ACButton calculator={this} />
            <BackspaceButton calculator={this} />
            <ToolButton calculator={this} text="()" ariaLabel="parenthesis" />
            <OperatorButton calculator={this} text="÷" event="/" />

            <NumberButton calculator={this} text="7" />
            <NumberButton calculator={this} text="8" />
            <NumberButton calculator={this} text="9" />
            <OperatorButton calculator={this} text="×" event="*" />

            <NumberButton calculator={this} text="4" />
            <NumberButton calculator={this} text="5" />
            <NumberButton calculator={this} text="6" />
            <OperatorButton calculator={this} text="−" event="-" />

            <NumberButton calculator={this} text="1" />
            <NumberButton calculator={this} text="2" />
            <NumberButton calculator={this} text="3" />
            <OperatorButton calculator={this} text="+" />

            <NumberButton calculator={this} text="0" />
            <NumberButton calculator={this} text="." ariaLabel="decimal point" />
            <NumberButton calculator={this} text="%" />

            <EqualsButton calculator={this} />
          </div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/aria-props */}
        <Dialog dialogRef={this.helpDialogRef} aria-description="help">
          <HelpDialogContent />
        </Dialog>
      </>
    );
  }
}

@observer
class JitDisplay extends React.Component<{ calculator: Calculator }> {
  containerRef = React.createRef<HTMLDivElement>();
  get container() {
    if (this.containerRef.current === null) {
      throw new Error("Calculator: containerRef is null");
    }
    return this.containerRef.current;
  }
  textElementRef = React.createRef<HTMLDivElement>();
  get textElement() {
    if (this.textElementRef.current === null) {
      throw new Error("Calculator: textElementRef is null");
    }
    return this.textElementRef.current;
  }
  scaleElementRef = React.createRef<HTMLDivElement>();
  get scaleElement() {
    if (this.scaleElementRef.current === null) {
      throw new Error("Calculator: scaleElementRef is null");
    }
    return this.scaleElementRef.current;
  }

  render() {
    const { justInTimeResult } = this.props.calculator;
    return (
      // Don't set role="math" here, as it causes Windows Narrator to read it twice.
      <div className="relative" aria-atomic="true" aria-live="polite">
        <ScreenReaderOnly>{justInTimeResult !== null ? "=" : ""}</ScreenReaderOnly>
        <div className="just-in-time-result relative" ref={this.containerRef}>
          <div className="absolute right-0 top-0 min-w-full origin-right" ref={this.scaleElementRef}>
            <div
              // Right padding to allow overshooting on text selection
              className="float-right pr-[40px] mr-[-40px]"
            >
              <div ref={this.textElementRef}>{justInTimeResult ?? ""}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  scale = 0;

  updateScale = () => {
    // The width of `this.container` is the available width for the text. The
    // width of its descendent `this.textElement` is the width of the actual
    // text contents. (Note that `this.textElement` is a div that's
    // right-aligned inside its parent. We can't use a right-aligned span here,
    // as it misbehaves when it gets too long for its container.)
    //
    // clientWidth is the width before applying transforms, unlike
    // getBoundingClientRect().width.
    let textWidth = this.textElement.clientWidth || 0;
    let availableWidth = this.container.clientWidth || 0;
    if (textWidth * Calculator.JIT_SCALE > availableWidth) {
      // Scale down to fit
      this.scale = availableWidth / textWidth;
    } else {
      this.scale = Calculator.JIT_SCALE;
    }
    // We scale the scaleElement, rather than the textElement itself, to help
    // selection behavior, especially on mobile. This way the scaleElement gets
    // the same height as the textElement. It's also full-width, and thus takes
    // up the entire line of the JIT result. When the user overshoots the text
    // when selecting, the scaleElement's presence behind it seems to make it
    // less likely for other things to get selected.
    this.scaleElement.style.transform = `scale(${this.scale})`;
  };

  componentDidMount() {
    this.updateScale();
    window.addEventListener("resize", this.updateScale);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScale);
  }

  componentDidUpdate() {
    this.updateScale();
  }
}

function parenBalance(expression: string): number {
  return (expression.match(/\(/g) || []).length - (expression.match(/\)/g) || []).length;
}

export function smartParenthesis(textBeforeCursor: string): string | null {
  if (/[\d.)%]$/.exec(textBeforeCursor)) {
    if (parenBalance(textBeforeCursor) > 0) {
      return ")";
    } else {
      return null;
    }
  } else if (/[eE]$/.exec(textBeforeCursor)) {
    return null;
  } else {
    return "(";
  }
}

export function ScreenReaderOnly({ children }: { children: React.ReactNode }) {
  return (
    <div
      style={{
        clip: "rect(0 0 0 0)",
        height: "1px",
        marginBottom: "-1px",
        overflow: "hidden",
        position: "absolute",
        width: "1px",
        userSelect: "none",
      }}
    >
      {children}
    </div>
  );
}
