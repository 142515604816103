import { ThemeContext } from "flowbite-react/lib/esm/components/Flowbite/ThemeContext";
import { Tabs } from "flowbite-react/lib/esm/components/Tab/Tabs";
import theme from "flowbite-react/lib/esm/theme/default";

// Warning: This component is full of tricky CSS layout to get the full-height
// tab content.

export default function HelpDialogContent() {
  return (
    <>
      <ThemeContext.Provider value={{ theme: theme }}>
        <Tabs.Group className="dark tabs !border-b-1 !gap-[1px]" aria-label="Tabs" style="underline">
          <Tabs.Item active={true} title="Help">
            <div className="flex flex-col mx-[20px] min-h-full">
              {/* Extra div layer to get block formatting context for margin collapse. */}
              {/* https://stackoverflow.com/a/43882888 */}
              <div>
                <div className="my-[20px]">
                  <h2>Keyboard shortcuts</h2>
                  <div className="section-body">
                    <table className="[&_td:last-child]:whitespace-nowrap">
                      <thead>
                        <tr>
                          <th className="w-[50%]">Key</th>
                          <th className="">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>+, -, *, /</td>
                          <td>+, −, ×, ÷</td>
                        </tr>
                        <tr>
                          <td>Enter</td>
                          <td>Calculate the result</td>
                        </tr>
                        <tr>
                          <td>Esc</td>
                          <td>Clear everything (AC)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <h2>Sharing calculations</h2>
                  <div className="section-body">
                    <p>
                      To share your current calculation with a friend, simply send them the current URL of the
                      calculator.
                    </p>
                  </div>
                  <h2>Percent calculation</h2>
                  <div className="section-body">
                    <p className="text-center text-[10px] tablet:text-[12px]">
                      For this example, remember that <span className="whitespace-nowrap">20% = 20 ÷ 100 = 0.2</span>.
                    </p>
                    <UsageTable>
                      <>What&apos;s 20% of $10?</>
                      <>10 × 20%</>
                      <>
                        10 × 0.2
                        <br />
                        <span className="aligned-equal">= </span>2
                      </>
                    </UsageTable>

                    <UsageTable>
                      <>Apply a 20% discount to $10.</>
                      <>10 − 20%</>
                      <>
                        10 × (1 − 0.2)
                        <br />
                        <span className="aligned-equal">= </span>10 × 0.8
                        <br />
                        <span className="aligned-equal">= </span>8
                      </>
                    </UsageTable>

                    <UsageTable>
                      <>Add 20% tax to $10.</>
                      <>10 + 20%</>
                      <>
                        10 × (1 + 0.2)
                        <br />
                        <span className="aligned-equal">= </span>10 × 1.2
                        <br />
                        <span className="aligned-equal">= </span>12
                      </>
                    </UsageTable>

                    <UsageTable>
                      <>If the price is $12 including 20% tax, what&apos;s the price without tax?</>
                      <>12 ÷ (100% + 20%)</>
                      <>
                        10 ÷ (1 + 0.2)
                        <br />
                        <span className="aligned-equal">= </span>12 ÷ 1.2
                        <br />
                        <span className="aligned-equal">= </span>10
                      </>
                    </UsageTable>
                  </div>
                </div>
              </div>
            </div>
          </Tabs.Item>

          <Tabs.Item title="About">
            <div className="flex flex-col mx-[20px] min-h-full">
              <div className="mt-[20px]">
                <p className="highlight">
                  This calculator was created with <Love />
                  <br />
                  by <a href="https://github.com/joliss">Jo Liss</a>
                  <br />
                  and <a href="https://github.com/bodil">Bodil Stokke</a>.
                </p>
                <p className="large">We love hearing from you!</p>
                <p className="large">
                  Please send your questions, feedback, or other inquiries to Jo at{" "}
                  <a href="mailto:jo.liss@sinopiaolive.com" className="border-b border-current">
                    jo.liss@sinopiaolive.com
                  </a>
                  .
                </p>
              </div>
              <div className="min-h-[20px] flex-grow flex-shrink-0"></div>
              <div className="mt-1 dim mb-[20px]">
                <p className="text-[10px] mb-[5px]">This site is owned and operated by:</p>
                <p className="leading-[1.5] mb-[15px]">
                  <strong className="font-bold">Sinopia Olive Limited</strong>
                  <br />
                  89 Spa Road, London, SE16 3SG
                  <br />
                  United Kingdom
                  <br />
                  Company number: 10151298
                  <br />
                  <a href="https://sinopiaolive.com/" className="border-b border-current">
                    sinopiaolive.com
                  </a>
                </p>
                <p>
                  Designed by:{" "}
                  <a href="https://oghenekaro.design/" className="border-b border-current">
                    oghenekaro.design
                  </a>
                </p>
              </div>
            </div>
          </Tabs.Item>
        </Tabs.Group>
      </ThemeContext.Provider>
    </>
  );
}

function UsageTable(props: { children: [React.ReactNode, React.ReactNode, React.ReactNode] }) {
  return (
    <div className="usage-table">
      <table>
        <tbody>
          <tr>
            <th scope="row" className="w-[33.33%]">
              Example
            </th>
            <td>{props.children[0]}</td>
          </tr>
          <tr>
            <th scope="row">You type</th>
            <td role="math">{props.children[1]}</td>
          </tr>
          <tr>
            <th scope="row" style={{ hyphens: "auto" }}>
              The app calculates
            </th>
            <td role="math">{props.children[2]}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function Love() {
  return (
    <svg className="inline h-[1.0em]" width="27" height="24" viewBox="0 0 27 24" role="text" aria-label="love">
      <path d="M13.3409 23.3409L2.3892 12.3892C1.51563 11.5156 0.933239 10.5 0.642046 9.34233C0.357955 8.18466 0.361506 7.03409 0.652699 5.89062C0.943892 4.74006 1.52273 3.73864 2.3892 2.88636C3.27699 2.01278 4.28906 1.43395 5.42543 1.14986C6.56889 0.858664 7.70881 0.858664 8.84517 1.14986C9.98864 1.44105 11.0043 2.01989 11.892 2.88636L13.3409 4.29261L14.7898 2.88636C15.6847 2.01989 16.7003 1.44105 17.8366 1.14986C18.973 0.858664 20.1094 0.858664 21.2457 1.14986C22.3892 1.43395 23.4048 2.01278 24.2926 2.88636C25.1591 3.73864 25.7379 4.74006 26.0291 5.89062C26.3203 7.03409 26.3203 8.18466 26.0291 9.34233C25.745 10.5 25.1662 11.5156 24.2926 12.3892L13.3409 23.3409Z" />
    </svg>
  );
}
