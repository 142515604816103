import Head from "next/head";
import { Calculator } from "../calculator";

export default function Home() {
  return (
    <>
      <Head>
        <title>Calculator</title>
      </Head>
      <Calculator />
    </>
  );
}
