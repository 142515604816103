import { Decimal } from "decimal.js";
export { Decimal };
Decimal.set({ precision: 100, rounding: Decimal.ROUND_HALF_UP });

type RenderDecimalOptions = {
  maxChars: number;
  decimalPlaces: number;
  ellipsis: string;
};

export function renderDecimal(decimal: Decimal, { maxChars, decimalPlaces, ellipsis }: RenderDecimalOptions): string {
  // Make 1/3*3 come out as 1
  let roundedDecimal = decimal.toSignificantDigits(Decimal.precision - 5);
  if (Decimal.precision < 50) {
    throw new Error("Decimal.precision must be at least 50, or our rounding code might need to be updated");
  }

  // The conditional is needed for performance, so we don't attempt to produce a
  // fixed representation of 1e100000000, which we wouldn't use anyway.
  if (Math.abs(roundedDecimal.e) <= maxChars) {
    // 1. Try unrounded
    let rendered = roundedDecimal.toFixed();
    if (rendered.length <= maxChars) {
      return rendered;
    }

    // 2. Try rounded to decimalPlaces
    let fixedDecimalPlaces = decimalPlaces;
    if (roundedDecimal.e < -1) {
      // Don't count leading zeros after "0." as decimal places. E.g. decimalPlaces
      // === 2 can still return 0.000012.
      fixedDecimalPlaces += -roundedDecimal.e - 1;
    } else {
      // Lower fixedDecimalPlaces if we have a lot of digits before the decimal
      // point, but never below 1.
      fixedDecimalPlaces = Math.min(
        fixedDecimalPlaces,
        Math.max(
          1,
          maxChars -
            roundedDecimal.trunc().toFixed().length - // number of digits before decimal point
            1 - // "."
            ellipsis.length
        )
      );
    }
    rendered = roundedDecimal.toFixed(fixedDecimalPlaces) + ellipsis;
    if (rendered.length <= maxChars) {
      return rendered;
    }
  }

  // 3. Fall back to scientific notation
  let rendered = roundedDecimal.toExponential(decimalPlaces);
  // Alternatively, let Decimal determine the number of significant digits
  let variableSd = roundedDecimal.toExponential();
  if (variableSd.length < rendered.length) {
    // Prefer "1e5" over "1.0000e5"
    rendered = variableSd;
  }
  if (!new Decimal(rendered).eq(roundedDecimal)) {
    // If we had to round, add ellipsis
    rendered = rendered.replace(/e/, ellipsis + "e");
  }
  return rendered;
}
