import React, { useCallback } from "react";
import { Calculator } from "./calculator";
import Icon from "@mdi/react";
import { mdiBackspaceOutline } from "@mdi/js";

let mostRecentTouchEnd = -Infinity;

type CalculatorButtonProperties = {
  className?: string;
  event?: string;
  calculator: Calculator;
  text?: string;
  ariaLabel?: string;
  children?: React.ReactNode;
};

export function CalculatorButton({
  className = "",
  event = undefined,
  calculator,
  text = "",
  ariaLabel = undefined,
  children = null,
}: CalculatorButtonProperties) {
  if (text === "" && children === null) {
    throw new Error("CalculatorButton: text or child content must be provided");
  }
  const buttonRef = React.createRef<HTMLButtonElement>();

  const onClickHandler = useCallback(() => {
    if (window.performance.now() - mostRecentTouchEnd < 500 && navigator.vendor !== "Apple Computer, Inc.") {
      // Mobile Firefox sometimes sends a click event despite preventDefault on
      // touchend, so we ignore those. But Safari sometimes sends click events
      // without a touchend (if you tap very fast), so we don't ignore those.
      return;
    }
    calculator.clickButton(event === undefined ? text : event);
  }, [calculator, event, text]);

  // Unlike native mobile apps, bobile browsers have a low tolerance for
  // registering clicks if the finger moves too much. Also, Android Chrome and
  // Samsung Internet ignore button clicks if you press too long. So we send a
  // click event on touchend.
  const onTouchEndHandler = useCallback(
    (e: React.TouchEvent) => {
      let { touches } = e.nativeEvent;
      if (touches.length === 0) {
        touches = e.nativeEvent.changedTouches;
      }
      for (let touch of touches) {
        let { clientX, clientY } = touch;
        let rect = buttonRef.current!.getBoundingClientRect();
        if (clientX >= rect.left && clientX <= rect.right && clientY >= rect.top && clientY <= rect.bottom) {
          mostRecentTouchEnd = window.performance.now();
          e.preventDefault();
          calculator.clickButton(event === undefined ? text : event); // note: event isn't e
        }
      }
    },
    [buttonRef, calculator, text, event]
  );

  return (
    <button
      ref={buttonRef}
      className={className}
      aria-label={ariaLabel ?? text}
      onMouseDown={(e) => {
        e.preventDefault();
      }} // do not focus on mousedown
      onClick={onClickHandler}
      onTouchEnd={onTouchEndHandler}
    >
      {children ?? text}
    </button>
  );
}

export function ACButton({ className = "", ...props }: CalculatorButtonProperties) {
  return <CalculatorButton text="AC" event="AC" className={`${className} b-ac`} {...props} />;
}

export function EqualsButton({ className = "", ...props }: CalculatorButtonProperties) {
  return <CalculatorButton text="=" className={`${className} b-equals`} {...props} />;
}

export function OperatorButton({ text = "", event = undefined, className = "", ...props }: CalculatorButtonProperties) {
  return <CalculatorButton text={text} event={event} className={`${className} b-operator`} {...props} />;
}

export function ToolButton({ text = "", event = undefined, className = "", ...props }: CalculatorButtonProperties) {
  return <CalculatorButton text={text} event={event} className={`${className} b-tool`} {...props} />;
}

export function NumberButton({ text = "", className = "", ...props }: CalculatorButtonProperties) {
  return <CalculatorButton text={text} event={text} className={`${className} b-number`} {...props} />;
}

export function BackspaceButton(props: CalculatorButtonProperties) {
  return (
    <ToolButton {...props} event="Backspace" ariaLabel="backspace">
      {/* aria-hidden is needed so iPad VoiceOver says "button" rather than "image" when touching icon. */}
      <Icon path={mdiBackspaceOutline} size="28px" color="inherit" aria-hidden="true" />
    </ToolButton>
  );
}
